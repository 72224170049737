import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Layout from '../../components/Layout'
import PageHeader from '../../components/general/PageHeader'
import { addContactMessage } from '../../services/api'

const Contact = () => {
  const [notificationMessage, setNotifcationMessage] = useState({})

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('الإسم مطلوب'),
      email: Yup.string().email().required('البريد اللإلكتروني مطلوب'),
      message: Yup.string().min(10).required('هذا الحقل مطلوب'),
    }),

    onSubmit: async (values, { resetForm }) => {
      // TODO: Add Form Submit handler
      // await addContactMessage(values)
      // Try and catch
      // Show error or success message
      // Reference: AddArticle.js
      try {
        await addContactMessage(values)
        resetForm()
        setNotifcationMessage({
          body: 'تم ارسال رسالتك بنجاح - شكرا لك',
          type: 'success',
        })
      } catch (err) {
        setNotifcationMessage({
          body: 'حدث خطأ أثناء ارسال الرسالة. الرجاء المحاولة مجددا',
          type: 'danger',
        })
      }
    },
  })

  return (
    <Layout>
      <PageHeader
        className="hassan_4"
        backgroundImage="/img/backgrounds/hassanbg_5.jpeg"
      >
        اتصل بنا
      </PageHeader>

      <section className="section">
        <div className="container">
          <div className="content">
            <form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="name" className="label">
                  الإسم
                </label>
                <input
                  className="input has-text-black"
                  type="text"
                  name="name"
                  {...formik.getFieldProps('name')}
                  placeholder="الاسم"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="help is-danger">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="email" className="label">
                  البريد الإلكتروني
                </label>
                <input
                  className="input has-text-black"
                  type="email"
                  name="email"
                  {...formik.getFieldProps('email')}
                  placeholder="البريد الالكتروني"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="help is-danger">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="message" className="label">
                  الرسالة
                </label>
                <div className="control">
                  <textarea
                    name="message"
                    className="textarea"
                    maxLength="400"
                    {...formik.getFieldProps('message')}
                    placeholder="أدخل الرسالة"
                  />

                  {formik.touched.message && formik.errors.message ? (
                    <div className="help is-danger">
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="field is-grouped">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-link"
                    disabled={!formik.isValid}
                  >
                    أرسل رسالة
                  </button>
                </div>
              </div>
              {notificationMessage.body && (
                <div
                  className={`notification is-${
                    notificationMessage.type || 'success'
                  } is-light`}
                >
                  <button
                    onClose
                    className="delete"
                    onClick={() => setNotifcationMessage({})}
                  />
                  {notificationMessage.body}
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
